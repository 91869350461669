<template>
    <LiefengContent>
        <template v-slot:title>订单管理</template>
        <template v-slot:toolsbarLeft>
            <div class="read-div">
                <div class="item">今日总金额：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.moneyOfNowDay : 0}}</div>
                <div class="item">今日总订单：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.orderOfNowDay : 0}}</div>
                <div class="item">昨日总金额：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.moneyOfYesterday : 0}}</div>
                <div class="item">昨日总订单：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.orderOfYesterday : 0}}</div>
                <div class="item">近七日总金额：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.moneyOfWeek : 0}}</div>
                <div class="item">近七日总订单：{{readDetail && JSON.stringify(readDetail) != '{}' ? readDetail.orderOfYWeek : 0}}</div>
            </div>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Select clearable transfer placeholder="请选择支付状态" v-model="payStatus" style="width: 200px; margin-left: 10px">
                        <Option value="1">等待支付</Option>
                        <Option value="2">支付中</Option>
                        <Option value="3">已支付</Option>
                        <Option value="4">支付失败</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select clearable transfer placeholder="请选择订单状态" v-model="orderStatus" style="width: 200px; margin-left: 10px">
                        <Option value="1">订单处理中</Option>
                        <Option value="2">订单完成</Option>
                        <Option value="3">订单取消</Option>
                    </Select>
                </FormItem>
                <Button type="primary" icon="ios-search-outline" style="margin-right: 10px" @click="getList">搜索</Button>
                <Button type="success" icon="ios-refresh" style="margin-right: 10px" @click="reset">重置</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            tableData: [], //table的数据
            loading: false, //table 加载
            page: 1, //分页
            total: 0, //总数量
            pageSize: 20, //每一页显示的数量
            tableColumns: [
                {
                    title: "订单标题",
                    align: "center",
                    minWidth: 100,
                    key: "orderName",
                },
                {
                    title: "订单备注",
                    align: "center",
                    minWidth: 100,
                    key: "orderRemark",
                },
                {
                    title: "支付备注",
                    align: "center",
                    minWidth: 100,
                    key: "payRemark",
                },
                {
                    title: "创建人姓名",
                    align: "center",
                    minWidth: 100,
                    key: "creatorName",
                },
                {
                    title: "头像",
                    align: "center",
                    minWidth: 100,
                    key: "portraitImg",
                    render: (h, params) => {
                        return params.row.portraitImg
                            ? h("img", {
                                  style: {
                                      width: "30px",
                                      height: "30px",
                                  },
                                  attrs: {
                                      src: params.row.portraitImg,
                                  },
                              })
                            : h("p", null, "-")
                    },
                },
                {
                    title: "支付ID",
                    align: "center",
                    minWidth: 100,
                    key: "payId",
                },
                {
                    title: "订单金额(元)",
                    align: "center",
                    minWidth: 100,
                    key: "orderAmount",
                    render: (h, params) => {
                        return h("div", {}, params.row.orderAmount && params.row.orderAmount != "" ? Number(params.row.orderAmount) / 100 : 0)
                    },
                },
                {
                    title: "实际支付金额(元)",
                    align: "center",
                    minWidth: 100,
                    key: "payerAmount",
                    render: (h, params) => {
                        return h("div", {}, params.row.payerAmount && params.row.payerAmount != "" ? Number(params.row.payerAmount) / 100 : 0)
                    },
                },

                {
                    title: "订单状态",
                    align: "center",
                    minWidth: 100,
                    key: "orderStatus",
                    render: (h, params) => {
                        return h("div", {}, params.row.orderStatus == 1 ? "订单处理中" : params.row.orderStatus == 2 ? "订单完成" : params.row.orderStatus == 3 ? "订单取消" : "未知状态")
                    },
                },
                {
                    title: "支付状态",
                    align: "center",
                    minWidth: 100,
                    key: "payStatus",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.payStatus == 1 ? "等待支付" : params.row.payStatus == 2 ? "支付中" : params.row.payStatus == 3 ? "已支付" : params.row.payStatus == 4 ? "支付失败" : "未知状态"
                        )
                    },
                },
                {
                    title: "支付方式",
                    align: "center",
                    minWidth: 100,
                    key: "channelPay",
                    render: (h, params) => {
                        return h("div", {}, params.row.channelPay == "weixin" ? "微信" : params.row.channelPay == "ali" ? "支付宝" : "未知支付方式")
                    },
                },
                {
                    title: "支付时间",
                    align: "center",
                    minWidth: 100,
                    key: "payTime",
                    render: (h, params) => {
                        return h("div", {}, params.row.payTime ? this.$core.formatDate(new Date(params.row.payTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "创建时间",
                    align: "center",
                    minWidth: 100,
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],

            payStatus: "", // 支付状态
            orderStatus: "", // 订单状态

            readDetail:{}
        }
    },
    created() {
        this.getList()
        this.getStatSpecialOrderStatCount()
    },
    methods: {
        reset() {
            this.payStatus = ""
            this.orderStatus = ""
            this.getList()
        },
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        // 获取列表接口数据
        getList() {
            this.loading = true
            this.$get("/gateway/api/sycompany/special/app/order/selectByPage", {
                page: this.page,
                pageSize: this.pageSize,
                supplierId: this.$route.query.supplierId || "",
                payStatus: this.payStatus,
                companyId: this.$route.query.companyId || "",
                orderStatus: this.orderStatus,
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList
                    this.page = res.currentPage
                    this.pageSize = res.pageSize
                    this.total = res.maxCount
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取统计数据
        getStatSpecialOrderStatCount(){
            this.$get('/gateway/api/sycompany/special/pc/order/getStatSpecialOrderStatCount',{
                companyId:this.$route.query.companyId
            }).then(res=>{
                if(res && res.code == 200){
                    this.readDetail = res.data
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.read-div{
    display: flex;
    .item{
        margin-right: 20px;
    }
}
</style>